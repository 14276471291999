import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { ContentContainer, MainContent, Gallery, Seo } from "../components"
import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa"

import "../css/portfolio.css"
import "../css/gallery.css"

const GalleryPage = ({ data }) => {
  const [modal, setModal] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [slideSide, setSlideSide] = React.useState(null)

  const items = data.allFile.edges

  const activeSlide = items[currentIndex].node

  const onClick = index => {
    setModal(true)
    setCurrentIndex(index)
  }

  const previous = () => {
    setSlideSide("left")
    currentIndex > 0
      ? setCurrentIndex(currentIndex - 1)
      : setCurrentIndex(items.length - 1)
  }

  const next = () => {
    setSlideSide("right")
    currentIndex < items.length - 1
      ? setCurrentIndex(currentIndex + 1)
      : setCurrentIndex(0)
  }

  return (
    <ContentContainer>
      <Seo
        title="Mockup Gallery"
        // image={seoImage}
        description="A compilation of all my mockups and design practices"
      />
      <MainContent>
        <div className="container">
          {/* <Gallery /> */}
          <section className="image-container">
            <h1>Image Gallery</h1>
            <div className="image-grid">
              {items.map(({ node }, index) => (
                <div
                  key={index}
                  onClick={() => onClick(index)}
                  className="image-item"
                >
                  <Img
                    fluid={node.childImageSharp.fluid}
                    alt={`${node.name} image`}
                    className="card-img"
                  />
                  <div className="card-content">
                    <p>Simple title for the card</p>
                  </div>
                </div>
              ))}
            </div>

            {modal && (
              <div className="gallery-modal">
                <div
                  className="modal-overlay"
                  onClick={() => setModal(false)}
                />

                <button onClick={() => previous()} className="modal-previous">
                  <FaArrowLeft />
                </button>

                <div
                  className={`modal-content modal-content--slide-${slideSide}`}
                  onAnimationEnd={() => setSlideSide(null)}
                >
                  <button
                    onClick={() => setModal(!modal)}
                    className="modal-close"
                  >
                    <FaTimes />
                  </button>

                  {activeSlide.childImageSharp && (
                    <div className="modal-image-container">
                      <Img
                        fluid={activeSlide.childImageSharp.fluid}
                        alt={`${activeSlide.name} image`}
                      />
                    </div>
                  )}

                  <h2>{activeSlide.name}</h2>
                  {activeSlide.technologies && (
                    <div className="item__technologies">
                      {activeSlide.technologies.map((technology, index) => (
                        <p key={index}>{technology}</p>
                      ))}
                    </div>
                  )}

                  {activeSlide.link && (
                    <a href={activeSlide.link}>Open website</a>
                  )}
                </div>

                <button onClick={() => next()} className="modal-next">
                  <FaArrowRight />
                </button>
              </div>
            )}
          </section>
        </div>
      </MainContent>
    </ContentContainer>
  )
}

export default GalleryPage

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "mockups" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
